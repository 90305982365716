#myProfile {
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
    color: $primary-purple;
    margin-bottom: 30px;
  }

  .el-row {
    margin-bottom: 46px;

    .el-col span {
      display: block;
      font-weight: bold;

      &:first-child {
        font-size: 18px;
        line-height: 1.28;
        color: $primary-green;
        margin-bottom: 9px;
      }
      &:nth-child(2) {
        font-size: 20px;
        line-height: 1.5;
        color: $primary-purple;
      }
    }

    .fr {
      @include rtl-sass-prop-sd(float, right);
      @include rtl-sass-prop-sd(left, unset);
    }

    .fl {
      @include rtl-sass-prop-sd(float, left);
      @include rtl-sass-prop-sd(right, unset);
    }
  }
}

@media (max-width: 1200px) {
  #myProfile .main .el-row {
    padding: 0 100px;
  }
}

@media (max-width: 767px) {
  #myProfile .main {
    padding: 32px 0 32px 0;
    .title {
      display: none;
    }
    .el-row {
      padding: 0 24px;
      margin-bottom: 0;

      .el-col span {
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: 550px) {
  #myProfile .main .el-row .el-col span {
    &:first-child,
    &:nth-child(2) {
      font-size: 16px;
    }
  }
}
